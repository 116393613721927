import React from "react"

import { withSearch } from "./withSearch"
import { SearchForm } from "./Form/SearchForm"
import { SearchLoading } from "./Loading/SearchLoading"
import { SearchPagination } from "./Pagination/SearchPagination"
import { SearchResults } from "./Results/SearchResults"
import { SearchEmpty } from "./Results/SearchEmpty"
import { SearchSensorHidden } from "./Sensors/SearchSensorHidden"
import { SearchSensorPublished } from "./Sensors/SearchSensorPublished"
import { SearchComponents } from "../../hooks/useSearch"

import { SearchPageContainer, SearchWrapper } from "./SearchStyles"

export const Search = withSearch(({ locales, products, resultsConfig, searchFormConfig, hoverImageIndex }) => (
  <>
    <SearchPageContainer width={`lg`}>
      <SearchWrapper>
        <SearchForm config={searchFormConfig} />
        <SearchSensorHidden />
        <SearchSensorPublished />
        <SearchComponents.ReactiveList
          {...resultsConfig}
          renderNoResults={() => <SearchEmpty locales={locales} products={products} search={true} />}
          render={({ data, loading }) =>
            loading ? (
              <SearchLoading pagination={resultsConfig?.size} />
            ) : (
              <SearchResults
                results={data}
                collectionOption={{
                  hoverImageIndex,
                }}
              />
            )
          }
          renderPagination={pagination => <SearchPagination {...pagination} />}
        />
      </SearchWrapper>
    </SearchPageContainer>
  </>
))
