import React from "react"
import { graphql } from "gatsby"

import { Search as Page } from "../components/Search/Search"

export const query = graphql`
  query {
    page: sanityPageSearch {
      metadata: _rawMetadata(resolveReferences: { maxDepth: 2 })
    }
  }
`

export default ({ data, ...props }) => <Page {...props} {...data} />
