import React, { useMemo } from "react"

import { useLanguage } from "../../hooks/useLanguage"
import { useSearch } from "../../hooks/useSearch"
import { useShopify } from "../../hooks/useShopify"
import { useTemplate } from "../../hooks/useTemplate"

export const withSearch = Component => ({ name = `Search`, location }) => {
  const { buildSortQuery, getDefaultSort, getSortOptions, getSearchResultsConfig, getSearchFormConfig } = useSearch(location)
  const { usePopularProducts } = useShopify({ max: 4 })
  const { collection: hoverImageIndex } = useTemplate()

  const searchFormConfig = getSearchFormConfig()
  const searchResultsConfig = getSearchResultsConfig()
  const products = usePopularProducts()
  const locales = {
    ...useLanguage(`collection`),
    ...useLanguage(`search`),
  }
  const defaultSort = getDefaultSort(`search`)
  const sorting = getSortOptions(`search`)
  const defaultSorting = sorting.find(({ label }) => label === defaultSort) || sorting[0]

  const sortQuery = useMemo(() => {
    if (searchFormConfig?.currentQuery) {
      const searchTerm = searchFormConfig?.currentQuery || ""
      const sortQuery = buildSortQuery("search", searchTerm, defaultSorting)

      return sortQuery
    }
  }, [searchFormConfig?.currentQuery])

  const resultsConfig = {
    ...searchResultsConfig,
    defaultQuery: () => ({
      sort: sortQuery,
    }),
  }

  Component.displayName = name
  return <Component locales={locales} products={products} resultsConfig={resultsConfig} searchFormConfig={searchFormConfig} hoverImageIndex={hoverImageIndex} />
}
