import React from "react"

import { SearchComponents } from "../../../hooks/useSearch"
import { SearchContainer, SearchInner, SearchLabel } from "../SearchStyles"

export const SearchForm = ({ config }) => (
  <SearchContainer>
    <SearchInner>
      <SearchLabel>{`Search:`}</SearchLabel>
      <SearchComponents.DataSearch {...config} className="SearchContainer" componentId="q" />
    </SearchInner>
  </SearchContainer>
)
